import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import API_URL from "../config";

import TokenService from "../services/token.service";

import {
  axiosInstanceAuth,
  axiosInstanceJWT,
} from "../utilities/axiosInstance";

const userString = sessionStorage.getItem("user");

const user = JSON.parse(userString);

const bankParame = user?.bank;

const initialState = {
  forgotPassword: {
    otpResponse: {
      verification_token: "",
      status: "",
    },
  },

  chirayuLoginDetails: {
    loginData: {
      loginDetails: {},
    },
  },
  otpDetails: {

    firstName: "",
    lastName: "",
    useremail: "",
    bankId: bankParame,
    role: ""
  },
  disticReportsData: {
    fromDate: "",
    toDate: "",
  },

  dashboardDetails: {},
  getAllDistrictData: {},
  data: user

};

export const getMisinformation = createAsyncThunk(
  "chirayu/misReport",
  async (data) => {



    const requestParam = data.page;
    const requestParam1 = data.page_size;
    const fromDate = data.from_date;
    const endDate = data.to_date;
    const bankParameter = data.bank_Parameter;
    const transactionStatus = data.trans_status === "ALL" ? "" : data.trans_status;
    const searchVal = data.searchVal;
    const paymentService = data.payment_mode === "ALL" || data.payment_mode === undefined ? "" : data.payment_mode;
    let disTrictName = ""
    if (data?.district_name !== undefined && data?.district_name !== null) {
      // console.log('undefined');
      disTrictName = data?.district_name;
    }

    // const seachparam  = searchVal!=="" ?? `&search=${searchVal}` 
    let apiReqUrl = `${API_URL.MIS_REPORTS}?page=${requestParam}&search=${searchVal}&size=${requestParam1}&from_date=${fromDate}&to_date=${endDate}&trans_status=${transactionStatus}&payment_service=${paymentService}&bank_id=${bankParameter}&district=${disTrictName}`

    // if(searchVal!=="" && searchVal!==undefined){
    //   apiReqUrl += `&search=${searchVal}`
    // }


    const response = await axiosInstanceJWT
      .get(apiReqUrl)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);


export const vleWiseReportsData = createAsyncThunk(
  "chirayu/misReport",
  async (data) => {


    const requestParam = data.page;
    const requestParam1 = data.page_size;
    const fromDate = data.from_date;
    const endDate = data.to_date;
    const bankParameter = data.bank_Parameter;
    // const transactionStatus=data.status;
    const transactionStatus = data.status === "ALL" ? "" : data.status;
    //   const paymentService=data.payment_mode === "ALL" ? "" : data.payment_mode;
    //   let disTrictName=""
    //   if(data?.district_name !== undefined && data?.district_name !== null) {
    //     // console.log('undefined');
    //     disTrictName = data?.district_name;
    // }


    const response = await axiosInstanceJWT
      .post(
        `${API_URL.VLEWISE_REPORTS}?page=${requestParam}&size=${requestParam1}&from_date=${fromDate}&to_date=${endDate}&status=${transactionStatus}`
      )
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const getDistictWiseData = createAsyncThunk(
  "chirayu/misReport",
  async (data) => {
    const { page, page_size, fromDate, toDate, bank_Parameter: bankParameter } = data;

    let disTrictName = "";


    // console.log("isDirect",isDirect)
    const response = await axiosInstanceJWT
      .get(
        `${API_URL.MIS_REPORTS}?page=${page}&size=${page_size}&from_date=${fromDate}&to_date=${toDate}&district=${disTrictName}&bank_id=${bankParameter}`
      )
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const getAllDistrict = createAsyncThunk(
  "getDistictWiseData/misReport",
  async (data) => {
    const fromDate = data.from_date;
    const endDate = data.to_date;
    const bankParameter = data.bank_Parameter;

    // console.log("isDirect",isDirect)
    const response = await axiosInstanceJWT
      .get(
        `${API_URL.GET_ALL_DISTRICT}?from_date=${fromDate}&to_date=${endDate}&bank_id=${bankParameter}`
      )
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const transactionEnquirey = createAsyncThunk(
  "transactionEnquirey/misReports",
  async (postData) => {


    const response = await axiosInstanceJWT
      .post(`${API_URL.MIS_REPORTS}transaction-enquiry-admin/`, postData)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const loginApi = createAsyncThunk(
  "loginApi/misReports",
  async (postData) => {
    const response = await axiosInstanceAuth
      .post(`${API_URL.LOG_IN}login/`, postData)
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);

export const otpVerificationApi = createAsyncThunk(
  "otpVerificationApi/misReports",
  async (postData) => {
    try {
      const response = await axiosInstanceAuth.post(
        `${API_URL.LOG_IN}login-verify/`,
        postData
      );
      TokenService.setUser(response?.data); // Set user using TokenService or handle it accordingly
      const resData = response.data; // Return the response data from the API call

      return resData;
    } catch (error) {
      // Handle errors if any
      return error.response;
    }
  }
);



export const getDashboardData = createAsyncThunk(
  "getDashboardData/misReports",
  async (postData) => {

    const bankParameter = postData?.bank_Parameter;
    const fromDate = postData?.fromDate;
    const endDate = postData?.endDate;

    let response;


    // Default URL logic if forDateApi is false
    response = await axiosInstanceJWT
      .get(
        `${API_URL.MIS_REPORTS}get-dashboard_data/?bank_id=${bankParameter}&from_date=${fromDate}&to_date=${endDate}`,
        postData
      )
      .catch((error) => {
        return error.response;
      });


    return response.data;
  }
);


export const misExportTransactions = createAsyncThunk(
  "challan/exportTransactions",
  async (data) => {
    const from_date = data.from_date;
    const to_date = data.to_date;
    const bankParameter = data?.bank_Parameter;
    const trans_status = data?.trans_status
    const paymentService = data.payment_mode


    const response = await axiosInstanceJWT
      .get(
        `${API_URL.EXPORT_MIS}mis/export-to-excel?from_date=${from_date}&to_date=${to_date}&bank_id=${bankParameter}&trans_status=${trans_status}&payment_service=${paymentService}`,
        {
          responseType: "arraybuffer",
        }
      )
      .catch((error) => {
        return error.response;
      });

    return response;
  }
);

export const misReportsSlice = createSlice({
  name: "chirayu",
  initialState,
  reducers: {
    saveEmail: (state, action) => {
      state.chirayuLoginDetails.loginData.loginDetails = action?.payload;
    },

    saveInitialDate: (state, action) => {
      state.disticReportsData.fromDate = action.payload.from_date;
      state.disticReportsData.toDate = action.payload.to_date;
    },
  },

  extraReducers: {
    [loginApi.fulfilled]: (state, action) => {
      state.forgotPassword.otpResponse = action.payload;
    },

    [loginApi.pending]: (state) => { },
    [loginApi.rejected]: (state) => { },


    [getAllDistrict.fulfilled]: (state, action) => {
      console.log("action", action.payload)
      console.log("state", state)
      state.getAllDistrictData = action.payload
    },
    [getAllDistrict.pending]: (state) => { },
    [getAllDistrict.rejected]: (state) => { },

    [getDashboardData.fulfilled]: (state, action) => {
      state.dashboardDetails = action.payload;
    },

    [getDashboardData.pending]: (state) => { },
    [getDashboardData.rejected]: (state) => { },

    [otpVerificationApi.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },

    [otpVerificationApi.fulfilled]: (state, action) => {
      // console.log("action", action);

      state.data = action.payload; // The response data from the API call

      state.otpDetails.firstName = action.payload.first_name;
      state.otpDetails.lastName = action.payload.last_name;
      state.otpDetails.useremail = action.payload.user_email;
      state.otpDetails.bankId = action.payload.bank;
      state.otpDetails.role = action.payload.role


    },
    [otpVerificationApi.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload; // The error data from the API call
    },

    [getAllDistrict.pending]: (state) => { },
  },
});
export const { saveEmail, saveInitialDate } = misReportsSlice.actions;
export const misReportsSliceReducer = misReportsSlice.reducer;
