import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./theme.scss";



import AllRoutes from "./AllRoutes";
import MyContext from "./custom-hooks/LangContext";
// import { theme, themeObj } from "./theme-config";

const App = () => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState(false);

  

  useEffect(() => {
    // login session expireTime if user not idle
    const expireTime = parseInt(sessionStorage.getItem("expiredTime"), 10);
    if (expireTime > 0 && expireTime > Date.now()) {
      setLogin(true);
    }
  }, []);

  // logout session expireTime if user not idle
  const logOutUser = (isLoggedIn) => {
    setLogin(isLoggedIn);
  };

  return (
    <MyContext.Provider >
      <React.Fragment>
        {login}
        <AllRoutes />
      </React.Fragment>
    </MyContext.Provider>
  );
};

export default App;
